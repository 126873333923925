@import '../../styles/variables.module.scss';

// Overwriding RECHARTS - TODO - use a dedicated file for those styles
.recharts-brush-slide {
  fill: $primaryColor;
  margin-top: 10px;
}

.recharts-wrapper {
  background-color: #fff;
  border-top: 0;
}

.recharts-brush {
  transform: translate(0px, 10px);
  rect {
    height: 20px;
    stroke: $primaryColor;
  }
}

.recharts-surface {
  overflow: visible;
}
.recharts-default-legend {
  display: none;
}

.recharts-brush-traveller > rect {
  width: 10px;
  fill: $primaryColor;
}

.recharts-brush-traveller {
  &:nth-of-type(1) {
    line {
      transform: translate(2px, 0px);
    }
  }
  &:nth-of-type(2) {
    line {
      transform: translate(-2px, 0px);
    }
  }
}
.recharts-brush-traveller:nth-of-type(2) > rect {
  transform: translate(-5px, 0);
}
.recharts-brush-texts {
  text:nth-of-type(1) {
    fill: $primaryColor;
    transform: translate(50px, 20px);
  }
  text:nth-of-type(2) {
    fill: $primaryColor;
    transform: translate(-50px, 20px);
  }
}

.last-day-brush {
  .recharts-brush-texts {
    text:nth-of-type(1) {
      transform: translate(15px, 20px);
    }
    text:nth-of-type(2) {
      transform: translate(-15px, 20px);
    }
  }
}

.last-week-brush {
  .recharts-brush-texts {
    text:nth-of-type(1) {
      transform: translate(20px, 20px);
    }
    text:nth-of-type(2) {
      transform: translate(-20px, 20px);
    }
  }
}

.last-month-brush {
  .recharts-brush-texts {
    text:nth-of-type(1) {
      transform: translate(15px, 20px);
    }
    text:nth-of-type(2) {
      transform: translate(-15px, 20px);
    }
  }
}
.recharts-layer {
  text {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    span {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }
  }
}

.recharts-legend-item-text {
  display: none;
}

.panning {
  cursor: grabbing;
}

.blurContent {
  filter: blur(4px);
  pointer-events: none;
}

.deviceDataValue {
  display: flex;
}

.deviceName {
  margin-bottom: 10px;
  svg {
    fill: $primaryColor;
    width: 20px;
    height: auto;
  }
}

.deviceHeader {
  margin-top: -40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px;
  background-color: $primaryColor;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid $borderColor;
  color: darkslategrey;
  font-weight: 600;
}
