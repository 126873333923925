@import '../../../styles/variables.module.scss';

.title {
  margin-bottom: 16px;
  font-family: 'Roboto Slab', arial;
  font-size: 30px;
}

.smallTitle {
  margin-bottom: 30px;
  font-family: 'Roboto', arial;
  font-size: 20px;
}

.subtitle {
  margin-bottom: 24px;
  color: $secondaryTextGray;
}

.errorInfo {
  margin-top: 10px;
  text-align: left;
  font-size: 12px;
}

.divider {
  margin: 10px 0 10px 0;
  display: flex;
  align-items: center;
  border: 0.5px solid #ddd;
}

.link {
  color: $primaryColor;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: $primaryColorHover;
  }
}

.logoutContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.actionContainer {
  padding-bottom: 10px;
}

.resetForm {
  background-color: transparent;
}
