@import '../../../styles/variables.module.scss';
.sideButton {
  right: -40px;
  position: absolute;
  top: 12px;
  padding: 10px;
  border-radius: 4px;
  z-index: $headerZIndexIcon;
  svg {
    fill: $primaryColor;
    width: 18px;
    height: 18px;
  }
}
