@import '../../styles/variables.module.scss';

.loginContainer {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loginContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/login_bg1.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.loginForm {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 270px;
}

.title {
  margin-bottom: 16px;
  font-family: 'Roboto Slab', arial;
  font-size: 30px;
}

.subtitle {
  margin-bottom: 24px;
  color: $secondaryTextGray;
}
.loginHeader {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 15px 30px;
  right: 0;
}
