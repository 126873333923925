$primaryColor: #008c45;
$primaryColorHover: #00a155;
$primaryColorHoverText: #02ca6d;
$primaryColorActive: #00783b;
$secondaryColor: #0e1833;

// $secondaryColor: #a18453;
$gray1: #cccccc;
$gray10: #a4a4a4;
$borderColor: #d3e6ed;
$headerBgColor: #fafafa;
$fontColor: #00160b;

$offlineColor: #c41000;
$onlineColor: #4f9c33;

$headerZIndex: 100;
$batteryWidth: 21;

//The following variables are exported as css variables in order to be able to be used in TSX files
//The TSX files that need those varoables are the tsx files created in order to overwrite default styles in ANT Design components styles
:export {
  primaryColor: $primaryColor;
  primaryColorHover: $primaryColorHover;
  primaryColorActive: $primaryColorActive;
  secondaryColor: $secondaryColor;
  gray1: $gray1;
  headerZIndex: $headerZIndex;
  borderColor: $borderColor;
  headerBgColor: $headerBgColor;
  batteryWidth: $batteryWidth;
}

$secondaryTextGray: $gray10;
$inputBorderColor: $gray10;

//ZIndex values
$headerZIndexIcon: 101;
