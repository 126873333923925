.languageSelectorIcon {
  width: 20px;
  height: 20px;
  margin-top: -1px;
}
.languageSelectorContainer {
  color: darkslategrey;
}
.languageSelector {
  cursor: pointer;
  display: flex;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkslategrey;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: darkslategrey;
  }
}
