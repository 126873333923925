@import '../../styles/variables.module.scss';

.batteryIcon {
  height: 20px;
  margin-right: 10px;
}

.batteryFull {
  color: green;
}

.batteryMedium {
  color: orange;
}

.batteryLow {
  color: $offlineColor;
}

.batteryStatus {
  display: flex;
  height: 20px;
}

.deviceOnline {
  svg {
    fill: green;
    margin-right: 5px;
  }
}

.deviceOffline {
  color: rgb(154, 154, 154);
  svg {
    fill: $offlineColor;
    margin-right: 5px;
  }
}

.linkEditContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .editIcon svg {
    width: 18px;
    height: auto;
    fill: $primaryColor;
    &:hover {
      fill: $primaryColorHoverText;
    }
  }
}

.actionIcons {
  gap: 15px;
  .checkIcon svg {
    fill: green;
    width: 18px;
    height: auto;
    margin-right: 10px;
    &:hover {
      opacity: 0.6;
    }
  }

  .closeIcon svg {
    fill: $offlineColor;
    width: 18px;
    height: auto;
    &:hover {
      opacity: 0.6;
    }
  }

  display: flex;
  width: 100%;
  justify-content: space-between;
}

.batteryNotSet {
  display: flex;
}

.deviceItem {
  display: flex;
  align-items: center !important;
}
.devicesList {
  padding: 8px 15px;
  background-color: #fff;
  margin: 10px 0;
}
