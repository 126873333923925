@import '../../../styles/variables.module.scss';

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  margin-left: 10px;
  padding-left: 10px;
}

.headerOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: darkslategrey;
}

@media screen and (max-width: 900px) {
  .headerTitle {
    display: none;
  }
  .headerOptions {
    position: absolute;
    right: 0;
    top: 2px;
  }
}
.headerLanguage {
  margin-right: 20px;
}

.headerWarningIcon {
  margin-right: 20px;
  display: flex;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 4px;
  cursor: pointer;
  svg {
    fill: $offlineColor;
    width: 21px;
    height: auto;
  }
}

.tooltip {
  color: #000;
}
