@import '../styles/variables.module.scss';
.siteLayoutBackground {
  padding: 20px;
  min-height: auto;
}
@media (max-width: 900px) {
  .siteLayoutBackground {
    padding: 0;
  }
}

.sensorIcon {
  fill: #fff;
  height: 20px;
  width: 20px;
  &.sensorIconFormula {
    height: 20px;
    width: 20px;
  }
  &.valvesIcon {
    height: 26px;
    width: 26px;
    margin-left: -4px !important;
    margin-top: 4px !important;
  }
  &.sensorListIcon {
    height: 18px;
    margin-right: 2px;
    //TODO - this is a temporary fix for the sidebar icon because every icon is not the same size - downloaded from different sources
    margin-left: -1px !important;
  }
  &.timelineListIcon {
    height: 28px;
    width: 28px;
    margin-right: -5px;
    //TODO - this is a temporary fix for the sidebar icon because every icon is not the same size - downloaded from different sources
    //and the icon is not centered so we need to adjust it when sidebar is collapsed
    margin-left: -2px !important;
    margin-top: 8px !important;
  }
}

.footer {
  text-align: center;
  padding: 10px;
  background: #fafafa;
  color: #b2b2b2;
}

.containerLayout {
  height: 100dvh;
  overflow: hidden;
}

.contentLayout {
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
}

.mobileMenu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $primaryColor;
  padding: 0;
  z-index: 10;

  .mobileMenuItem {
    color: #fff;
    font-size: 14px;
    opacity: 0.9;
    font-weight: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 18px;
    width: 100%;
    &.mobileMenuItemSelected {
      background-color: $primaryColorHover;
      padding: 6px 18px;
    }
    .sensorListIcon {
      margin-top: 2px;
      margin-left: 0 !important;
    }
    .valvesIcon {
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
    .userIcon {
      svg {
        fill: #fff;
        height: 20px;
        width: 20px;
      }
    }
    .timelineListIcon {
      margin-top: 0 !important;
      margin-left: 0 !important;
    }
    .mobileItem1 {
      margin-top: 2px;
    }
    .mobileItem2 {
      margin-top: 7px;
    }
    .mobileItem3 {
      margin-top: -2px;
    }
    .mobileItem4 {
      margin-top: 6px;
    }
  }
}
