@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Roboto/Roboto/Roboto-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Roboto/Roboto_Slab/static/RobotoSlab-Regular.ttf') format('ttf');
}

body {
  font-family: 'Roboto', sans-serif;
}

//TODO - remove this
@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Slab&display=ttf');
@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto&display=ttf');
