@import '../../styles/variables.module.scss';

.loginContainer {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loginContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/login_bg1.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
}
.loginForm {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 270px;
}

.title {
  margin-bottom: 16px;
  font-family: 'Roboto Slab', arial;
  font-size: 30px;
}

.smallTitle {
  margin-bottom: 30px;
  font-family: 'Roboto', arial;
  font-size: 20px;
}

.subtitle {
  margin-bottom: 24px;
  color: $secondaryTextGray;
}

.errorInfo {
  margin-top: 10px;
  text-align: left;
  font-size: 12px;
}

.divider {
  margin: 10px 0 10px 0;
  display: flex;
  align-items: center;
  border: 0.5px solid #ddd;
}

.link {
  color: $primaryColor;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: $primaryColorHover;
  }
}

.logoutContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.actionContainer {
  padding-bottom: 10px;
}
