@import 'styles/variables.module.scss';

.statusIcon {
  border-radius: 50%;
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: -2px;
}

.deviceOfflineBadge {
  background-color: #f4ece8;
  color: $offlineColor;
  padding: 5px 15px;
  border-radius: 15px;
  height: 28px;
  display: inline-flex;
  position: relative;
  align-items: center;
  svg {
    fill: $offlineColor;
  }
  &.isMobile {
    padding: 2px;
    height: 30px;
    width: 30px;
    display: block;
    margin-left: 5px;
    position: relative;
    .statusIcon {
      margin-left: 1px;
      margin-top: 1px;
    }
    .badgeText {
      display: none;
    }
  }
}

.deviceOnlineBadge {
  background-color: #ebf0ed;
  color: $onlineColor;
  padding: 5px 15px;
  border-radius: 15px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  position: relative;
  &.isMobile {
    padding: 0;
    height: 30px;
    width: 30px;
    display: block;
    margin-left: 5px;
    display: block;
    .statusIcon {
      margin-left: 4px;
      margin-top: 3px;
    }
    .badgeText {
      display: none;
    }
  }
  svg {
    fill: $onlineColor;
  }
}

.badgeText {
  margin-left: 20px;
}
.offlineDevicesMessage {
  margin-bottom: 20px;
}

.devicesOfflineWarning {
  color: #000;
}

.isMobile {
  .statusIcon {
    display: block;
    height: 24px;
    width: 24px;
    padding: 3px;
    margin-left: 3px;
    margin-top: 2px;
  }
  &.hasMobileTextBadge {
    height: 28px;
    display: inline-flex;
    align-items: center;
    position: relative;
    width: auto;
    padding: 5px 15px;
    .badgeText {
      display: block;
    }
    .statusIcon {
      margin-left: -5px;
      margin-right: 5px;
      margin-top: 1px;
    }
  }
}
