@import '../../styles/variables.module.scss';
@import '../../styles/mixins.scss';

.timeLineList {
  background-color: #fff;
  padding: 40px;
}
.timelineRow {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  background-color: #f5f5f5;
  padding: 10px;
}
.timelineText {
  margin-left: 15px;
  font-size: 14px;
  color: darkslategrey;
}
.timelineTime {
  font-size: 14px;
  color: #b2b2b2;
  border-right: 1px solid #b2b2b2;
  padding-right: 15px;
  min-width: 95px;
  max-width: 95px;
  font-weight: 300;
  span {
    width: 90px;
    display: block;
  }
}

@media screen and (max-width: 900px) {
  .timelineTime {
    max-width: 55px;
    min-width: 55px;
    span {
      width: 55px;
      display: block;
    }
  }
}
.hiddenChildren {
  visibility: hidden;
}
.emptyTimelineContainer {
  display: block;
  background-color: #fff;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 30px;
  top: 0;
  bottom: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.infiniteScrollContainer {
  overflow: hidden;
}

.emptyTimelineContent {
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
  .emptyTimelineTitle {
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .emptyTimelineDescription {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
    font-weight: 100;
  }
}
.timelineContainer {
  background-color: #fff;
  padding: 40px 0;
  min-height: 100%;
  position: relative;
  @include pageStyle;
}
.spinnerInfiniteScroll {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
  margin-top: 20px;
}

.contentOn {
  background-color: #fff;
  border: 1px solid #6aca6a;
  border-radius: 6px;
}

.contentOff {
  background-color: #fafafa;
  border: 1px solid #eee;
  border-radius: 6px;
}
.spinnerContainer {
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  width: 22px;
}
