.tenantSelector {
  margin-right: 20px;
  border-radius: 20px;
  border: 0;
  box-shadow: none;
}
.mobileTenantSelector {
  padding-left: 0;
}

@media screen and (max-width: 900px) {
  .tenantSelector {
    margin-top: 17px;
  }
}
