@import '../../styles/variables.module.scss';
@import '../../styles/mixins.scss';

.dashboardIcon svg {
  height: 25px;
}

.dashboardStatisticContent {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

.dashboardStatisticNumber {
  font-size: 50px;
  font-weight: bold;
  margin: 0 10px;
  line-height: 50px;
}

.dashboardTitleContent {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    fill: #67838e;
    height: 20px;
  }

  .dashboardTitle {
    color: #67838e;
    font-size: 14px;
    margin-left: 5px;
  }
}

.dashboardStatisticSubtitle {
  font-size: 14px;
  text-align: center;
  color: #67838e;
}
.dashboardTitleContent {
  display: flex;
  align-items: center;
}

.dashboardCard {
  box-shadow:
    0 0px 0px rgba(0, 0, 0, 0.05),
    0 3px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0;
  border: 1px solid rgba(211, 230, 237, 1);
  background-color: #fff;
}
.pendingActuator {
  display: flex;
  font-size: 12px;
  .pendingRow {
    position: absolute;
    margin-left: -85px;
    margin-top: 2px;
    flex-direction: row-reverse;
  }
}

.actuatorEditIcon {
  line-height: 20px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  span {
    max-width: 70%;
  }
  svg {
    fill: $primaryColor;
    width: 25px;
    height: 18px;
    &:hover {
      fill: $primaryColorHover;
    }
  }
}
.actuatorList {
  background-color: #fff;
  border: 1px solid rgb(211, 230, 237);
  .actuatorHeader {
    background-color: rgb(240, 240, 240);
    border-bottom: 1px solid rgb(211, 230, 237);
    border-radius: 0px;
    padding: 15px 0;
    text-align: center;
    font-weight: bold;
    color: darkslategrey;
    font-size: 14px;
  }
  .actuatorContent {
    padding: 0px 15px;
  }
}

.actuatorsTable {
  margin: 20px 0;
  .titleRow {
    text-align: center;
    padding: 0;
    font-weight: bold;
  }
}
.actuatorItem {
  display: flex;
  align-items: center !important;
}
.actuatorRow {
  .actuatorEditIcon {
    svg {
      display: none;
    }
  }
  &:hover {
    .actuatorEditIcon {
      svg {
        display: flex;
      }
    }
  }
}
.editRow {
  .itemState {
    display: none;
  }
}
.editContainer {
  display: flex;
  width: 100%;
  .actionIcons {
    margin-left: 10px;
    .checkIcon {
      padding: 5px;
      transition: all 0.5s;
      border: 1px solid transparent;
      border-radius: 4px;
      &:hover {
        background-color: rgba(219, 240, 219, 0.4);
        border: 1px solid rgba(186, 254, 186, 0.4);
      }
      svg {
        fill: rgba(0, 128, 0, 0.88);
        &:hover {
          fill: green;
        }
      }
    }
    .closeIcon {
      padding: 5px;
      transition: all 0.5s;
      border: 1px solid transparent;
      border-radius: 4px;
      &:hover {
        background-color: rgba(255, 238, 238, 0.6);
        border: 1px solid rgba(249, 222, 222, 0.6);
      }
      svg {
        fill: rgba(255, 0, 0, 0.89);
      }
    }
  }
}
