.overviewContent {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-around;
}

.graphOverviewParent {
  height: 200px;
}

.overviewGraph {
  border: 1px solid rgba(211, 230, 237, 1);
  box-shadow:
    0 0px 0px rgba(0, 0, 0, 0.05),
    0 3px 5px rgba(0, 0, 0, 0.05);
  padding: 30px;
  transition:
    box-shadow 0.3s ease,
    transform 0.3s ease;
  background-color: #fff;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 5px 8px rgba(0, 0, 0, 0.08),
      0 5px 8px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
  }
}

.graphOverviewTitle {
  margin-bottom: 20px;
  display: flex;
  color: rgba(103, 131, 142, 1);
  color: darkslategrey;
  font-weight: 400;
  &.luminosity {
    svg {
      height: 32px;
    }
  }
  &.soilMoisture {
    svg {
      height: 30px;
    }
  }
  &.airHumidity {
    svg {
      height: 30px;
    }
  }
  &.pressure {
    svg {
      height: 35px;
    }
  }

  .graphOverviewValue {
    font-size: 24px;
    color: #cc8500;
  }
  .graphOverviewUnit {
    font-size: 14px;
    color: rgba(103, 131, 142, 1);
  }
}

.overviewGraph {
  flex: 1 1 30%;
  min-width: 300px;
  box-sizing: border-box;
}

.graphOverviewIconContainer {
  display: flex;
  align-items: flex-start;
}

.graphOverviewFooter {
  margin-top: 15px;
  text-align: center;
  color: rgb(164 164 164);
  font-weight: 300;
}

.blurContent {
  filter: blur(4px);
}

.noDataOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none; /* Prevent interaction with overlay */
  margin-top: 80px;
}

.noDataMessage {
  font-size: 20px;
  color: #2a454e;
  margin-top: -80px;
}
.overviewGraph {
  position: relative;
}

.graphOverviewValues {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  .graphOverviewValue {
    margin-right: 10px;
    font-size: 18px;
  }
}
.graphOverviewLabel {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  margin-right: 5px;
}
.graphOverviewLabelContainer {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.graphOverviewMinMax {
  font-size: 18px;
  margin-left: 10px;
}
.graphOverviewGraphTitle {
  line-height: 16px;
}
