@import '../../../styles/variables.module.scss';

.avatar {
  background-color: $primaryColor;
  width: 36px;
  height: 36px;
  color: #fff;
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  cursor: pointer;
  color: darkslategrey;
}

.userInfo {
  margin-left: 15px;
  color: darkslategrey;
}

.userName {
  font-size: 14px;
  font-weight: 700;
  color: $secondaryColor;
  color: darkslategrey;
}

.userEmail {
  font-size: 14px;
  font-weight: 400;
  color: $secondaryColor;
  color: darkslategrey;
}
@media screen and (max-width: 900px) {
  .userName {
    display: none;
  }
  .userEmail {
    display: none;
  }
}

.profileArrowIcon {
  fill: $secondaryColor;
  width: 20px;
  height: 15px;
}
