@import '../../styles/variables.module.scss';

.resetContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  > div {
    background-color: transparent;
  }
}

.resetForm {
  background: white;
  text-align: center;
  width: 300px;
}

.resetTitle {
  margin-bottom: 16px;
  font-family: 'Roboto', arial;
  font-size: 16px;
  color: #000;
  text-align: center;
  font-weight: 300;
  margin-top: 20px;
}

.mobileUser {
  height: auto;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  position: relative;

  height: 100%;
  .mobileUserHeader {
    margin-bottom: 20px;
    background-color: #fff;
    height: 70px;
  }
  .userBubble {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primaryColor;
    border: 1px solid #ddd;
    color: #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 0 auto;
    margin-top: -30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
  .user {
    margin-top: 40px;
    height: calc(100% - 40px);
    border-radius: 20px 20px 0 0;
    background-color: #fff;
    display: flex;
    flex-flow: column;
    border: 1px solid #d3e6ed;
  }
  .userRow {
    padding: 10px 20px 10px 50px;
    color: darkslategrey;
    font-weight: 300;
  }
  .userLabel {
    font-size: 16px;
    font-weight: 600;
    min-width: 100px;
    display: block;
    margin-bottom: 10px;
    color: darkslategrey;
  }
  .userActions {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 30px;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    bottom: 10px;
  }

  .userInfo {
    padding-top: 30px;
  }

  .resetPassword {
    font-size: 14px;
    color: $primaryColor;
    cursor: pointer;
    &:hover {
      color: $primaryColorHover;
    }
  }
}

.resetPassMobile {
  background-color: red;
  z-index: 1000;
}

.resetFormMobileContainer {
  background: white;
  text-align: center;
  width: 100%;
  top: 0px;
  position: absolute;
  height: 0;
  z-index: 1;
  transform: translateY(120%);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  overflow: hidden;
  &.visible {
    transform: translateY(0);
    height: calc(100% - 90px);
  }

  .resetFormMobile {
    background: white;
    text-align: center;
    width: 300px;
    margin: 0 auto;
    margin-top: 30px;
  }
}

.headerBack {
  background-color: #fafafa;
  border-radius: 4px 4px 0 0;
  padding: 15px 15px;
  border-bottom: 1px solid #d3e6ed;
  color: darkslategrey;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  svg {
    fill: $primaryColor;
    width: 20px;
    height: auto;
  }
}

.backLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  color: $primaryColor;
}
