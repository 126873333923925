.customTooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  .customTooltipLabel {
    margin-bottom: 5px;
  }
  .customTooltipFooter {
    color: #969696;
  }
}

.tabPaneLoading > div {
  height: 100%;
  width: 100%;
}
.customTooltipLabelMark {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: -5px;
}
