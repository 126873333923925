@import '../../styles/variables.module.scss';
@import '../../styles/mixins.scss';

.deviceEventContainer {
  height: calc(100vh - 300px);
  margin-top: 20px;
  margin: 20px;
  transition: 0.3s all;
  width: auto;
  position: relative;
  padding-right: 20px;
  color: darkslategrey;
  .scrollMessage {
    display: none;
  }
  &:hover {
    box-shadow: 0px 0px 20px #d1d1d1;

    .scrollMessage {
      display: block;
      position: absolute;
      color: #a7a7a7;
      z-index: 2;
      margin-top: 10px;
      left: 50%;
      transform: translateX(-50%);
      top: 4px;
    }
  }
}

.deviceEventChart {
  width: 100%;
  height: calc(100% - 250px);
}

.customTooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  .customTooltipLabel {
    margin-bottom: 5px;
  }
  .customTooltipFooter {
    color: #969696;
  }
}

.loadingGraph {
  left: 50%;
  position: absolute;
  margin-top: 10%;
}

.deviceEventFilterContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
}

.tabPaneLoading > div {
  height: 100%;
}

.deviceInfo {
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  .uptimeMessage {
    width: 100%;
    margin-right: 10px;
  }
}

.settingsIcon svg {
  height: 20px;
  width: auto;
  margin-left: 10px;
}
.settingsAction {
  cursor: pointer;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.noDataOverlay {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none; /* Prevent interaction with overlay */
}

.noDataMessage {
  font-size: 20px;
  color: #2a454e;
  margin-top: -55px;
}

.blurContent {
  filter: blur(4px);
  pointer-events: none;
}

.deviceDataContainer {
  display: block;
  border: 1px solid $borderColor;
}

.deviceDataHeader {
  background-color: $headerBgColor;
  border-radius: 4px 4px 0 0;
  padding: 15px 15px;
  border-bottom: 1px solid $borderColor;
  color: darkslategrey;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  .backButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    color: $primaryColor;
    svg {
      fill: $primaryColor;
      width: 20px;
      height: auto;
    }
  }
}
.deviceDataContent {
  background-color: #fff;
  border-radius: 4px;
  padding: 5px;
}
.deviceDataValue {
  display: flex;
  color: darkslategrey;
  font-weight: 300;
  line-height: 18px;
  width: 100%;
}
.deviceDataRow {
  display: flex;
  padding: 10px;
  align-items: center;
  min-height: 45px;
  .deviceDataLabel {
    min-width: 150px;
    color: darkslategrey;
    font-weight: 600;
  }
}
.deviceInfoExtra {
  width: 12px;
  height: 12px;
  margin-top: -4px;
  margin-left: 5px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.deviceDataTootlipMessage {
  color: #000;
}

.editDevice {
  svg {
    width: 16px;
    height: 16px;
    fill: $primaryColor;
    margin-top: -1px;
  }
  margin-left: 10px;
}

.editContainer {
  display: flex;
  width: 100%;
  max-width: 600px;
}

.deviceStatusTooltip {
  margin-left: -7px;
  margin-top: -7px;
  background-color: white;
}
.actionIcons {
  margin-left: 10px;
  .checkIcon {
    padding: 5px;
    transition: all 0.5s;
    border: 1px solid transparent;
    border-radius: 4px;
    &:hover {
      background-color: rgba(219, 240, 219, 0.4);
      border: 1px solid rgba(186, 254, 186, 0.4);
    }
    svg {
      fill: rgba(0, 128, 0, 0.88);
      &:hover {
        fill: green;
      }
    }
  }
  .closeIcon {
    padding: 5px;
    transition: all 0.5s;
    border: 1px solid transparent;
    border-radius: 4px;
    &:hover {
      background-color: rgba(255, 238, 238, 0.6);
      border: 1px solid rgba(249, 222, 222, 0.6);
    }
    svg {
      fill: rgba(255, 0, 0, 0.89);
    }
  }
}
.singleTabContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  flex-direction: column;
}

.singleTabContent {
  background-color: #fff;
  width: 100%;
  border: 1px solid #d3e6ed;
  margin-top: -1px;
}

.singleTabLabel {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  align-self: baseline;
  background-color: #fff;
  border: 1px solid #d3e6ed;
  padding: 8px 16px;
  border-radius: 8px 8px 0 0;
  border-bottom: 0;
  z-index: 1;
  color: $primaryColor;
  line-height: 22px;
}
