@import 'styles/variables.module.scss';

.batteryIcon {
  height: 26px;
  margin-right: 5px;
  position: relative;
  margin-bottom: -8px;
  .batteryCharge {
    display: block;
    width: #{$batteryWidth}px;
    height: 11px;
    background-color: red;
    position: absolute;
    opacity: 1;
    margin-top: 8px;
    margin-left: 1px;
    border-radius: 2px;
  }
}

.battery_green {
  color: green;
}

.battery_orange {
  color: orange;
}

.battery_red {
  color: $offlineColor;
}

.batteryStatus {
  display: flex;
  height: 20px;
  align-items: baseline;
  width: 70px;
  color: darkslategrey;
}
