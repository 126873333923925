@import './variables.module.scss';
@import './mixins.scss';

.tato-link {
  color: $primaryColor;
  text-decoration: none;
  transition: opacity 200ms;
  &:hover {
    color: $primaryColorHoverText;
  }
}

.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  background-color: #fff;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 30px;
  @include pageStyle;
  min-height: 300px;
}

.emptyContent {
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
  .emptyTitle {
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 20px;
    color: darkslategrey;
  }
  .emptyDescription {
    font-size: 16px;
    color: #555;
    font-weight: 100;
    line-height: 24px;
    width: 80%;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-bottom: 30px;
    color: darkslategrey;
  }
}

.actuatorIllustration,
.sensorIllustration {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  margin-top: 30px;
}

.sensorIllustration {
  height: 150px;
  width: auto;
}

strong {
  font-weight: 600;
}
